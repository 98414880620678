import { useRootStore } from "store";
import { useMission } from "views/Mission/TeamSpecV2/MissionContext";
import { blankSpec, ServerSpecV3 } from "views/Mission/TeamSpecV2/types";
import { SpecV3 } from "../../types";
import { checkMissionCompleteness } from "views/Mission/TeamSpecV2/utils/spec";
import { useHistory } from "react-router-dom";
import { mapServerSpecToState } from "views/Mission/TeamSpecV2/utils/mappings/spec.mapping";
import queryKeys from "queries/keys";
import MissionSpec from "models/MissionSpec";
import { getBaseSpecificity } from "hooks/useQueryKeyAuth";

// Non-hook helper for query key specificity
const nonHookGetQueryKeySpecificity = ({
  missionSpecId,
  roles,
  v3,
}: {
  missionSpecId: string;
  roles?: boolean;
  v3?: boolean;
}) => {
  return {
    missionSpecId,
    roles,
    v3,
  };
};

export const useOnSpecSuccess = () => {
  const history = useHistory();
  const {
    missionSpecStore,
    queryClient,
    authStore: { token },
    accountsStore: { currentAccountId },
  } = useRootStore();
  const { setMission, setShowErrors, setPristineMission, setReadonly } =
    useMission();

  return (data: Partial<ServerSpecV3>) => {
    const savedId = data._id as string;
    setReadonly(true);
    setShowErrors(false);

    const currentUrl = location.pathname;
    const missionId = /^\/mission\/([^/]+)\/?/.exec(currentUrl)?.[1];

    if (missionId === "new" && savedId) {
      setMission({ ...blankSpec });
      setPristineMission({} as SpecV3);

      const newUrl = currentUrl.replace(missionId, savedId);
      history.push(newUrl);
    }

    // Tell missionSpecStore to update the mission
    const draft = mapServerSpecToState(data as ServerSpecV3);
    setMission(draft);
    setPristineMission(draft);

    // Tell the missionSpecStore that the mission has been saved
    missionSpecStore.invalidateMissionCache();
    missionSpecStore.setMission(data as unknown as MissionSpec);

    // Invalidate the mission spec query
    queryClient.invalidateQueries({
      queryKey: queryKeys.missionSpecs.byId(
        nonHookGetQueryKeySpecificity({
          ...getBaseSpecificity(token, currentAccountId),
          missionSpecId: savedId,
          v3: true,
        })
      ).queryKey,
      exact: false,
    });

    // Invalidate the mission spec query
    queryClient.invalidateQueries({
      queryKey: queryKeys.missionSpecs.byId(
        nonHookGetQueryKeySpecificity({
          missionSpecId: savedId,
          v3: true,
        })
      ).queryKey,
      exact: false,
    });
  };
};

export const useOnSpecError = (backupMessage?: string) => {
  const { uiStore } = useRootStore();

  return (error: unknown) => {
    if ((error as Error)?.message !== "Project details are incomplete") {
      uiStore.setApiErrorToast(error, backupMessage || "Something went wrong");
    }
  };
};

export const useValidateOrError = () => {
  const {
    uiStore,
    userStore: { user },
  } = useRootStore();
  const { mission, setShowErrors } = useMission();

  return () => {
    const errors = checkMissionCompleteness(mission, user)?.errors || [];
    if (errors.length) {
      setShowErrors(true);

      uiStore.setToast({
        text: "Some information is missing",
        type: "error",
      });

      throw new Error("Project details are incomplete");
    }

    setShowErrors(false);
  };
};

export function clearMissionLocalStorage() {
  try {
    const missionKeyPrefixes = ["mission-", "pristine-mission-"];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && missionKeyPrefixes.some((prefix) => key.startsWith(prefix))) {
        localStorage.removeItem(key);
      }
    }
  } catch (e) {
    console.warn("Failed to clear mission local storage", e);
  }
}
