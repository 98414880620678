import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { SpecV3, blankSpec, SpecError, ServerSpecV3 } from "./types";
import { checkMissionCompleteness } from "views/Mission/TeamSpecV2/utils/spec";
import { useLocation } from "react-router-dom";
import { useFetchSpec } from "views/Mission/TeamSpecV2/hooks/specs/useFetchSpec";
import {
  mapServerSpecToState,
  mapStateToServerSpec,
} from "views/Mission/TeamSpecV2/utils/mappings/spec.mapping";
import { toJS } from "mobx";
import {
  computeMissionDiff,
  guessTimezone,
} from "views/Mission/TeamSpecV2/utils/general";
import { useRootStore } from "store";
import { USE_LOCAL_STORAGE } from "views/Mission/TeamSpecV2/constants";
import { useFetchPrefill } from "views/Mission/TeamSpecV2/hooks/specs/useFetchPrefill";

interface MissionContextType {
  pristineMission?: SpecV3;
  setPristineMission: (mission: SpecV3) => void;
  mission: SpecV3;
  hasChangesToSave: boolean;
  setMission: (mission: SpecV3) => void;
  updateMissionField: <K extends keyof SpecV3>(
    key: K,
    value: SpecV3[K]
  ) => void;
  errors: SpecError[];
  showErrors: boolean;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  readonly: boolean;
  setReadonly: (readonly: boolean) => void;
  loading: boolean;
  resetNewMission: () => void;
  isLoadingError: boolean;
}

const MissionContext = createContext<MissionContextType | undefined>(undefined);

export const MissionProvider = ({ children }: { children: ReactNode }) => {
  const {
    userStore: { user },
  } = useRootStore();
  const [userReadonly, setUserReadonly] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const location = useLocation();
  const missionId = /^\/mission\/([^/]+)\/?/.exec(location.pathname)?.[1];
  const {
    data: fetchedMission,
    isFetching,
    isError: isLoadingError,
  } = useFetchSpec(missionId);
  const { data: prefill, isFetching: prefillLoading } =
    useFetchPrefill(missionId);
  const [pristineMission, setPristineMissionState] = useLocalStorage<SpecV3>(
    `pristine-mission-${missionId}`,
    {} as SpecV3,
    USE_LOCAL_STORAGE
  );

  const createNewMission = () => ({
    ...blankSpec,
    updatedAt: new Date(),
    timezone: guessTimezone(),
  });

  const [mission, setMissionState] = useLocalStorage<SpecV3>(
    `mission-${missionId}`,
    createNewMission(),
    USE_LOCAL_STORAGE
  );

  const setMission = (mission: SpecV3) => {
    return toJS(setMissionState({ ...mission }));
  };

  const setPristineMission = (mission: SpecV3) => {
    return toJS(setPristineMissionState({ ...mission }));
  };

  const resetNewMission = () => {
    setMission({ ...blankSpec, _id: "new" });
    setPristineMission({} as SpecV3);
  };

  const updateMissionField = <K extends keyof SpecV3>(
    key: K,
    value: SpecV3[K]
  ) => {
    setMissionState((prev: SpecV3): SpecV3 => {
      const updatedMission: SpecV3 = {
        ...prev,
        [key]: value,
        updatedAt: new Date(),
      };
      return updatedMission;
    });
  };

  const readonly = useMemo(() => {
    if (mission.status === "published") {
      return true;
    } else if (mission.status === "spec") {
      return false;
    } else {
      return userReadonly;
    }
  }, [mission.status, userReadonly]);

  const setReadonly = (value: boolean) => {
    if (!value && mission.status === "published") {
      console.warn(
        'Cannot set non-readonly state for mission with status "published"'
      );
      return;
    }
    setUserReadonly(value);
  };

  // Check for unsaved changes
  const hasChangesToSave = useMemo(() => {
    const original = mapStateToServerSpec(pristineMission as SpecV3);
    const updated = mapStateToServerSpec(mission as SpecV3);
    return Object.keys(computeMissionDiff(updated, original)).length > 0;
  }, [mission, pristineMission]);

  const errors = useMemo(() => {
    return checkMissionCompleteness(mission, user)?.errors || [];
  }, [mission, user]);

  useEffect(() => {
    setShowErrors(false);

    if (missionId === "new") {
      setUserReadonly(false);
      setPristineMission({} as SpecV3);

      if (prefill) {
        setMission({ ...prefill, timezone: guessTimezone() } as SpecV3);
      }

      // Make sure that new missions don't have any old data
      if (mission._id !== "new") {
        resetNewMission();
      }
    } else {
      setUserReadonly(true);
    }
  }, [missionId, mission._id, prefill]);

  // Update mission when fetched
  useEffect(() => {
    if (missionId && missionId !== "new" && fetchedMission) {
      const spec = mapServerSpecToState(fetchedMission as ServerSpecV3);

      // Set pristine mission to the db state
      setPristineMission(spec);

      // If the mission is different from the fetched mission, update the state
      if (mission._id !== missionId) {
        setMission(spec);
      }
    }
  }, [missionId, fetchedMission]);

  useEffect(() => {
    // cleanup when leaving the page
    return () => {
      resetNewMission();
    };
  }, []);

  return (
    <MissionContext.Provider
      value={{
        hasChangesToSave,
        pristineMission,
        setPristineMission,
        mission,
        setMission,
        updateMissionField,
        errors,
        showErrors,
        setShowErrors,
        loading: isFetching || prefillLoading,
        readonly,
        setReadonly,
        resetNewMission,
        isLoadingError,
      }}
    >
      {children}
    </MissionContext.Provider>
  );
};

export const useMission = () => {
  const context = useContext(MissionContext);
  if (!context) {
    throw new Error("useMission must be used within a MissionProvider");
  }
  return context;
};
