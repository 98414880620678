import UserObject, {
  UserBadge as UserBadgeType,
} from "@a_team/models/dist/UserObject";
import { AuthStore } from "store/Auth";
import serviceFetch from "./helpers";

const RESOURCE = "search-service/";

export enum ArchExpertiseTags {
  ProductStrategy = "Product Strategy",
  SolutionArchitecture = "Solution Architecture",
  InnovationConsultant = "Innovation Consultant",
  MachineLearning = "Machine Learning",
  GenAI = "GenAI",
  TechnologyResearch = "Technology Research",
  SoftwareArchitect = "Software Architect",
  OperationsConsultant = "Operations Consultant",
  Design = "Design",
  UX = "UX",
  AI = "AI",
  DataEngineering = "Data Engineering",
  DataAnalysis = "Data Analysis",
  CloudExpert = "Cloud Expert",
  MobileDev = "Mobile Dev",
  SystemsArchitecture = "Systems Architecture",
  FrontEndOptimization = "Front-end Optimization",
}

enum ArchIndustryTags {
  Aerospace = "Aerospace",
  Biotech = "Biotech",
  Retail = "Retail",
  SupplyChain = "Supply Chain",
  Manufacturing = "Manufacturing",
  B2BSaaS = "B2B SaaS",
  NewsAndJournalism = "News & Journalism",
  Fintech = "Fintech",
  Marketplaces = "Marketplaces",
  Blockchain = "Blockchain",
  Cloud = "Cloud",
  ARVRXR = "AR/VR/XR",
  Entertainment = "Entertainment",
  Edtech = "Edtech",
  AI = "AI",
  AIML = "AI/ML",
  Cybersecurity = "Cybersecurity",
  OpenSource = "Open Source",
  IOT = "iOT",
  EnterpriseSoftware = "Enterprise Software",
  Innovation = "Innovation",
  DigitalTransformation = "Digital Transformation",
  FoodAndBeverage = "Food & Beverage",
  SaaS = "SaaS",
  ECommerce = "E-Commerce",
  Agriculture = "Agriculture",
  Hardware = "Hardware",
  Healthcare = "Healthcare",
  Mobile = "Mobile",
  B2C = "B2C",
}

enum ArchCompanyStages {
  Enterprise = "Enterprise",
  VCBacked = "VC-backed",
}

export interface AdvisorDetailsObject {
  headline: string;
  about: string[];
  expertiseTags: ArchExpertiseTags[];
  industryTags: ArchIndustryTags[];
  companyStages: ArchCompanyStages[];
  callAgenda?: string[];
  engagementOptions?: any;
  requestedAt?: Date;
}

export type TargetedBuilderCountPayload = {
  roleCategoryId: string;
  requiredSkills?: string;
  preferredSkills?: string;
  clientRateMin?: string;
  clientRateMax?: string;
  countries?: string;
  minimumAvailabilityHours?: string;
  whTimezone?: string;
  whStartDate?: string;
  whStartTime?: string;
  whEndTime?: string;
  whMinutes?: string;
};

export const getAllBuilderCount = async (
  auth: AuthStore
): Promise<{
  totalRecords: number;
}> => serviceFetch(auth, `${RESOURCE}count`, {}, "get");

export const getTargetedBuilderCount = async (
  payload: TargetedBuilderCountPayload,
  auth: AuthStore,
  signal?: AbortSignal
): Promise<{
  totalRecords: number;
}> =>
  serviceFetch(
    auth,
    `${RESOURCE}targeted/count`,
    payload,
    "get",
    null,
    undefined,
    { signal }
  );

export interface TargeterSuggestedBuilder {
  uid: string;
  firstName: string;
  lastName: string;
  username: string;
  title: string;
  badges: UserBadgeType[];
  profileURL: string;
  profilePictureURL: string;
  yearsExperience: number;
  location: {
    city: string;
    country: string;
  };
  hourlyRate: {
    max: number;
    min: number;
  };
  rateRange: {
    max: number;
    min: number;
  };
  appliedSkills: Array<string>;
  verifiedSkills: any;
  availability?: {
    availability?: {
      notes: string;
      type: string;
      weeklyHoursAvailable: number;
    };
    reminderSentAt: string;
    updatedAt: string;
  };
  assignedSpecializations: any;
  workingHours: {
    name: string;
    daily: Array<{
      startTime: number;
      endTime: number;
    }>;
    utcDaylightHours: Array<{
      startTime: number;
      endTime: number;
    }>;
    utcStandardHours: Array<{
      startTime: number;
      endTime: number;
    }>;
  };
  aboutMe: string;
  linkedin: {
    username: string;
  };
  metadata: {
    totalOverlapMinutes: number;
  };
}

export interface TargeterSuggestedBuildersResponse {
  users: Array<TargeterSuggestedBuilder>;
}

export const getTargeterSuggestedBuilders = async (
  payload: TargetedBuilderCountPayload,
  auth: AuthStore,
  signal?: AbortSignal
): Promise<TargeterSuggestedBuildersResponse> =>
  serviceFetch(
    auth,
    `${RESOURCE}targeter/suggested-builders`,
    payload,
    "get",
    null,
    undefined,
    { signal }
  );

export type ListSkillsResponse = {
  _id: string;
  name: string;
}[];

export type ListSkillsPayload = {
  ids?: string;
  query?: string;
};

export const listSkillsApi = async (
  payload: ListSkillsPayload,
  auth: AuthStore
): Promise<ListSkillsResponse> =>
  serviceFetch(auth, `${RESOURCE}list-skills`, payload, "get");

export type ListIndustriesResponse = {
  _id: string;
  name: string;
}[];

export type ListIndustriesPayload = {
  ids?: string;
  query?: string;
};

export const listIndustriesApi = async (
  payload: ListIndustriesPayload,
  auth: AuthStore
): Promise<ListIndustriesResponse> =>
  serviceFetch(auth, `${RESOURCE}list-industries`, payload, "get");

// TODO:Put this in @ateam/models
export interface UserObjectOverride extends UserObject {
  linkedin?: { username: string };
  advisorDetails?: AdvisorDetailsObject;
  everHadAnInterviewWithClient?: boolean;
}

export const getUserProfileByUserId = async (
  uid: string,
  auth: AuthStore
): Promise<UserObjectOverride> =>
  serviceFetch(auth, `${RESOURCE}user/${uid}/profile`, null, "get");

export type ListCitiesPayload = {
  cityName?: string;
  countryCode?: string;
};
export type ListCitiesResponse = {
  cityNames: string[];
};
export const listCitiesApi = async (
  payload: ListCitiesPayload,
  auth: AuthStore
): Promise<ListCitiesResponse> =>
  serviceFetch(auth, `${RESOURCE}v1-list-cities`, payload, "get");
