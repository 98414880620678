import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";
import {
  ServerRoleV3,
  ServerSpecV3,
  SpecV3,
} from "views/Mission/TeamSpecV2/types";

const base = "v3/mission-specs";

// Missions
export const prefillMissionSpecV3 = async (
  auth: AuthStore
): Promise<Partial<SpecV3>> => serviceFetch(auth, `${base}/prefill`);

export const createMissionSpecV3 = async (
  auth: AuthStore,
  body: Partial<ServerSpecV3>
): Promise<Partial<ServerSpecV3>> =>
  serviceFetch(auth, `${base}/`, null, "post", body);

export const updateMissionSpecV3 = async (
  auth: AuthStore,
  mid: string,
  body: Partial<ServerSpecV3>
): Promise<Partial<ServerSpecV3>> =>
  serviceFetch(auth, `${base}/${mid}`, null, "put", body);

export const requestMissionSpecV3 = async (
  auth: AuthStore,
  mid: string,
  body: ServerSpecV3
): Promise<ServerSpecV3> =>
  serviceFetch(auth, `${base}/${mid}/request`, null, "put", body);

export const publishMissionSpecV3 = async (
  auth: AuthStore,
  mid: string,
  body: ServerSpecV3,
  dealId?: string
): Promise<ServerSpecV3> =>
  serviceFetch(
    auth,
    `${base}/${mid}/publish?hubspotDealId=${dealId}`,
    null,
    "put",
    body
  );

export const deleteMissionSpecV3 = async (
  auth: AuthStore,
  mid: string
): Promise<void> => serviceFetch(auth, `${base}/${mid}`, null, "delete");

export const getMissionSpecV3 = async (
  auth: AuthStore,
  mid: string
): Promise<Partial<ServerSpecV3>> => serviceFetch(auth, `${base}/${mid}`);

// Roles
export const saveNewRoleV3 = async (
  auth: AuthStore,
  mid: string,
  body: Partial<ServerRoleV3>
): Promise<ServerRoleV3> =>
  serviceFetch(auth, `${base}/${mid}/role`, null, "post", body);

export const updateRoleV3 = async (
  auth: AuthStore,
  mid: string,
  rid: string,
  body: Partial<ServerRoleV3>
): Promise<ServerRoleV3> =>
  serviceFetch(auth, `${base}/${mid}/role/${rid}`, null, "put", body);

export const deleteRoleV3 = async (
  auth: AuthStore,
  mid: string,
  rid: string
): Promise<void> =>
  serviceFetch(auth, `${base}/${mid}/role/${rid}`, null, "delete");

export const getRolePendingReviewIdV3 = async (
  auth: AuthStore,
  mid: string,
  rid: string
): Promise<string | null> =>
  serviceFetch(auth, `${base}/${mid}/role/${rid}/pending-review-id`);

// Role extensions - adding roles to running missions
export const saveNewRoleExtensionV3 = async (
  auth: AuthStore,
  mid: string,
  body: Partial<ServerRoleV3>
): Promise<ServerRoleV3> =>
  serviceFetch(auth, `${base}/${mid}/role-extension`, null, "post", body);
