import { HiringTimeline } from "@a_team/models/dist/ClientRegistration";
import { TRACKING_STORAGE_KEY } from "analytics";
import { ValidationMessage as Message } from "helpers/formValidation";
import { withHttps } from "helpers/strings";
import cookie from "js-cookie";
import { UTMParams } from "models/User";
import * as Yup from "yup";

export type UTMLogEntry = UTMParams & { timestamp: string; domain: string };

export const credentailsSchema = Yup.object({
  firstName: Yup.string().required(Message.REQUIRED),
  lastName: Yup.string().required(Message.REQUIRED),
  email: Yup.string().email(Message.INVALID_EMAIL).required(Message.REQUIRED),
  password: Yup.string()
    .matches(/[a-z]/) // 1 lowercase character
    .matches(/[A-Z]/) // 1 uppercase character
    .matches(/[0-9]/) // 1 numeric character
    .min(8) // 8-character minimum
    .required(Message.REQUIRED),
});

export type CredentialsFormValues = Yup.InferType<typeof credentailsSchema>;

export const companySchema = Yup.object({
  name: Yup.string().required(Message.REQUIRED),
  jobTitle: Yup.string().required(Message.REQUIRED),
  website: Yup.string()
    .transform((value) => withHttps(value))
    .url(Message.INVALID_URL)
    .required(Message.REQUIRED),
  employeeRange: Yup.string().required(Message.REQUIRED),
  fundingRange: Yup.string().required(Message.REQUIRED),
  revenueRange: Yup.string().required(Message.REQUIRED),
});

export type CompanyFormValues = Yup.InferType<typeof companySchema>;

export const EMPLOYEE_RANGES = [
  { value: "1-10", label: "1-10 employees" },
  { value: "11-50", label: "11-50 employees" },
  { value: "51-100", label: "51-100 employees" },
  { value: "101-200", label: "101-200 employees" },
  { value: "201-500", label: "201-500 employees" },
  { value: "501-1000", label: "501-1,000 employees" },
  { value: "1001-5000", label: "1,001-5,000 employees" },
  { value: "5001+", label: "5,001+ employees" },
];

export const FUNDING_RANGES = [
  { value: "N/A", label: "Not Applicable" },
  { value: "0-50000", label: "$0 - $50,000" },
  { value: "50001-500000", label: "$50,001 - $500,000" },
  { value: "500001-1M", label: "$500,001 - $1 million" },
  { value: "1M-5M", label: "$1 million - $5 million" },
  { value: "5M-10M", label: "$5 million - $10 million" },
  { value: "10M-25M", label: "$10 million - $25 million" },
  { value: "25M-50M", label: "$25 million - $50 million" },
  { value: "50M-100M", label: "$50 million - $100 million" },
  { value: "100M-500M", label: "$100 million - $500 million" },
  { value: "500M-1B", label: "$500 million - $1 billion" },
  { value: "1B+", label: "$1 billion+" },
];

export const REVENUE_RANGES = [
  { value: "0-100000", label: "$0 - $100,000" },
  { value: "100001-500000", label: "$100,001 - $500,000" },
  { value: "500001-1M", label: "$500,001 - $1 million" },
  { value: "1M-5M", label: "$1 million - $5 million" },
  { value: "5M-10M", label: "$5 million - $10 million" },
  { value: "10M-50M", label: "$10 million - $50 million" },
  { value: "50M-100M", label: "$50 million - $100 million" },
  { value: "100M-500M", label: "$100 million - $500 million" },
  { value: "500M-1B", label: "$500 million - $1 billion" },
  { value: "1B-5B", label: "$1 billion - $5 billion" },
  { value: "5B+", label: "$5 billion+" },
];

export const HIRING_TIMELINES = [
  {
    value: HiringTimeline.Immediate,
    label: "We need people to start immediately",
  },
  {
    value: HiringTimeline.NextMonth,
    label: "We need people to start next month",
  },
  {
    value: HiringTimeline.Within3Months,
    label: "We need people to start within 3 months",
  },
  { value: HiringTimeline.Exploring, label: "We're just exploring" },
];

export type EmployeeRange = typeof EMPLOYEE_RANGES[number]["value"];
export type FundingRange = typeof FUNDING_RANGES[number]["value"];
export type RevenueRange = typeof REVENUE_RANGES[number]["value"];

/**
 * Retrieves the UTM parameters from several backup sources, for the
 * purpose of attributing them to users.
 *
 * The order of precedence is: cookie > local storage > current URL parameters.
 *
 * @returns The UTM data object or null if not found.
 */
export const getUtms = (): UTMLogEntry | null => {
  try {
    const url = new URL(window.location.href);

    const currentUtmParams: UTMParams = {
      source: url.searchParams.get("utm_source"),
      medium: url.searchParams.get("utm_medium"),
      campaign: url.searchParams.get("utm_campaign"),
      term: url.searchParams.get("utm_term"),
      content: url.searchParams.get("utm_content"),
      referralCode: url.searchParams.get("referralCode"),
      metadataCid: url.searchParams.get("metadataCid"),
    };

    const utmCookie = cookie.get(TRACKING_STORAGE_KEY);
    const utmLocalStorage = localStorage.getItem(TRACKING_STORAGE_KEY);
    const decodeStoredValue = (val = "") => {
      try {
        return JSON.parse(decodeURIComponent(escape(atob(val))));
      } catch (_e) {
        return null;
      }
    };

    const utmData: {
      utmLog: UTMLogEntry[];
    } = utmCookie
      ? decodeStoredValue(utmCookie)
      : utmLocalStorage
      ? decodeStoredValue(utmLocalStorage)
      : { utmLog: [] };

    const firstUtmLogEntry = utmData?.utmLog[0];

    return firstUtmLogEntry || currentUtmParams || null;
  } catch (_e) {
    return null;
  }
};
