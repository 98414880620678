export enum CompanyStageCategory {
  Startup = "startup",
  Growth = "growth",
  Enterprise = "enterprise",
}

export type CompanyIndustry = {
  name: string;
  id: string;
};

export interface NormalizedCompany {
  name: string;
  domain: string;
  website: string;
  industries: CompanyIndustry[];
  funding: number | null;
  revenue: { min: number | null; max: number | null; actual: number | null };
  employees: number | null;
  stage: CompanyStageCategory | null;
}

export enum IndustryNames {
  Healthcare = "Healthcare",
  Wellness = "Wellness & Mental Health",
  MentalHealth = "Mental Health",
  Pharma = "Pharma",
  Fitness = "Fitness",
  FinancialServices = "Financial Services",
  FinancialServicesAndFintech = "Financial Services / Fintech",
  Banking = "Banking",
  Insurance = "Insurance & InsurTech",
}
