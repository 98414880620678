/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Footer } from "components/Auth/Footer";
import { useLocation, useHistory } from "react-router-dom";
import { CustomTheme, Typography, Row, Column } from "@a_team/ui-components";

import handsSvg from "./assets/hands.svg";
import brokenLogoSvg from "./assets/broken-logo.svg";
import logoSvg from "./assets/logo.svg";
import TrackButton from "components/TrackButton";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { CSSProperties } from "@emotion/serialize";
import { HomeLocation } from "Locations";
import { observer } from "mobx-react-lite";
import { useRootStore } from "store";
import { SUPPORT_EMAIL } from "configs/emails";

const getCSSRules: CSSRulesResolver = ({
  margins,
  breakpoints,
}: CustomTheme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "220px 160px 280px",
    height: "calc(100vh - 546px)",
    [`@media (max-width: ${breakpoints.lg}px)`]: {
      padding: "150px 70px 200px",
      height: "calc(100vh - 386px)",
    },
    [`@media (max-width: ${breakpoints.md}px)`]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      padding: 15,
      height: "calc(100vh - 106px)",
    },
  },
  title: {
    marginTop: 32,
    marginBottom: 12,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginTop: 16,
      marginBottom: 24,
    },
  },
  logoSvg: {
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
  brokenLogoSvg: {
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
  handsSvg: {
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      display: "none",
      flexGrow: 0,
    },
  },
  row: {
    width: "100%",
    marginLeft: "0 !important",
    marginRight: "0 !important",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      height: "100%",
      textAlign: "center",
      flexDirection:
        "column-reverse !important" as CSSProperties["flexDirection"],
    },
  },
  column: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    maxWidth: "490px !important",
    [`@media (max-width: ${breakpoints.md}px)`]: {
      "&:first-child": {
        marginBottom: margins.lg,
      },
    },
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      maxWidth: "100% !important",
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: 80,
    },
  },
  buttonsContainer: {
    display: "flex",
    columnGap: 8,
  },
  block1: {
    marginTop: 0,
    marginBottom: 12,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginBottom: 8,
    },
  },
  block2: {
    marginTop: 0,
    marginBottom: 20,
    fontWeight: 400,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginBottom: 16,
    },
  },
  block3: {
    marginTop: 0,
    marginBottom: 8,
    fontWeight: 400,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginBottom: 12,
    },
  },
});

export interface ErrorPageProps {
  title?: string;
  description?: string;
  errorCode?: number | null;
  links?: [string, string][];
}

const ErrorPage: FC<ErrorPageProps> = ({
  description = "Sorry, the page you are looking for doesn’t exist or has been moved.",
  errorCode = null,
  links = [],
  title = "Something doesn't add up",
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const history = useHistory();
  const location = useLocation<Record<string, unknown>>();
  const {
    uiStore: { isMobile },
  } = useRootStore();

  const logo = isMobile ? brokenLogoSvg : logoSvg;

  return (
    <>
      <div css={styles.root}>
        <Row justify="between" css={styles.row}>
          <Column css={styles.column}>
            <img src={logo} alt="logo" css={styles.logoSvg} />
            <img
              src={brokenLogoSvg}
              alt="broken logo"
              css={styles.brokenLogoSvg}
              width="64"
            />
            <Typography
              variant={`h${isMobile ? "2" : "0"}`}
              color="Grey@900"
              css={styles.title}
            >
              {title}
            </Typography>
            <Typography variant="h4" color="Grey@900" css={styles.block1}>
              Error Code: {errorCode || location.state?.code || 404}
            </Typography>
            <Typography variant="h4" color="Grey@600" css={styles.block2}>
              {description} If you need immediate help from us, please{" "}
              <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
                contact our team
              </a>
              .
            </Typography>
            <Typography variant="h4" color="Grey@600" css={styles.block3}>
              Also, here are some helpful links:
            </Typography>
            <div css={styles.buttonsContainer}>
              <TrackButton
                name="Go Back"
                variant="secondary"
                size={isMobile ? "lg" : "md"}
                onClick={() => {
                  history.go(-1);
                  window.location.reload();
                }}
              >
                Go Back
              </TrackButton>
              {links.map(([title, url], index) => (
                <TrackButton
                  key={`error-page--links--${index}`}
                  name={title.toLowerCase()}
                  size={isMobile ? "lg" : "md"}
                  onClick={() => {
                    window.location.href = url;
                  }}
                >
                  {title}
                </TrackButton>
              ))}
              {(!links || links.length === 0) && (
                <TrackButton
                  name="Take me Home"
                  size={isMobile ? "lg" : "md"}
                  onClick={() => {
                    window.location.href = HomeLocation;
                  }}
                >
                  Back to Discover page
                </TrackButton>
              )}
            </div>
          </Column>
          <Column css={{ ...styles.column, ...styles.handsSvg }}>
            <img src={handsSvg} alt="hands" css={styles.handsSvg} />
          </Column>
        </Row>
      </div>

      <Footer />
    </>
  );
};

export default observer(ErrorPage);
