/** @jsxImportSource @emotion/react */

import { Checkbox, Modal, Typography } from "@a_team/ui-components";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { FC, useState } from "react";
import { userAcceptTermsAndConditions } from "services/user";
import { useRootStore } from "store";

const AcceptTermsModal = () => {
  const [accepted, setAccepted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    authStore: auth,
    uiStore: { setToast },
    userStore,
  } = useRootStore();

  const styles = useCSSRulesWithTheme(() => ({
    container: {
      padding: 0,
    },
    header: {
      marginBottom: 4,
    },
    description: {
      marginBottom: 8,
    },
    checkBox: {
      marginBottom: 20,
    },
  }));

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      await userAcceptTermsAndConditions(auth);
      await userStore.updateUser(true);
    } catch (e) {
      setToast({ text: "Unable to save", type: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={true} canClose={false} variant="action" zIndex={10005}>
      <div css={styles.container}>
        <Typography
          variant="textLarge"
          weight="bold"
          component="div"
          css={styles.header}
        >
          To continue, you must accept our Terms of Service
        </Typography>

        <div css={styles.description}>
          <Typography variant="textMedium">
            Please review and accept A.Team’s{" "}
            <a
              target="_blank"
              href="https://platform.a.team/client-terms-of-service"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a target="_blank" href="https://platform.a.team/privacy-policy">
              Privacy Policy
            </a>
            .
          </Typography>
        </div>
        <div css={styles.checkBox}>
          <Checkbox
            onChange={() => setAccepted(!accepted)}
            checked={accepted}
            label="I have reviewed and accept the terms and policy."
            data-testing-id="accept-terms-of-service-checkbox"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TrackButton
            name="Accept terms and conditions"
            onClick={handleSubmit}
            disabled={!accepted || submitting}
            loading={submitting}
            data-testing-id="accept-terms-of-service-submit"
          >
            Submit
          </TrackButton>
        </div>
      </div>
    </Modal>
  );
};

const ForcingModalProvider: FC = ({ children }) => {
  const {
    userStore: { user },
    authStore: { isLoggedIn },
  } = useRootStore();

  return (
    <div>
      {children}
      {isLoggedIn && user?.uid && user?.needsAcceptTOS && <AcceptTermsModal />}
    </div>
  );
};

export default observer(ForcingModalProvider);
