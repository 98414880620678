/**
 * @note Event names use titlecase and past tense
 * @example "Completed Thing"
 *
 * What to track on the frontend?
 *  - UI related events
 *
 * What to defer to the backend for tracking?
 *  - Events that indicate a change in data (ex: Mission Status)
 *  - User property changes
 */

export const ACCOUNT_CREATED = "Account Created";
export const ACCOUNT_SELECTED = "Account Selected";
export const BUILDER_DISCOVERED: string = "Builder Discovered";
export const BUTTON_CLICKED: string = "Button Clicked";
export const DASHBOARD_RESOURCE_CARD_CLICKED =
  "Dashboard Resource Video Card Clicked";
export const EXAMPLE_TEAM_CARD_CLICKED: string = "Example Team Card Clicked";
export const IDENTIFIED: string = "Identified";
export const INSERT_MISSION_SPEC_DESCRIPTION_TEMPLATE_CLICKED =
  "Insert Mission Spec Description Template Clicked";
export const LINK_CLICKED = "Link Clicked";
export const MODAL_CLOSED: string = "Modal Closed";
export const MODAL_OPENED: string = "Modal Opened";
export const PAGE: string = "Page Viewed";
export const PROPOSAL_BUILDER_SELECTED = "Proposal Builder Selected";
export const BUILDER_PROFILE_SHARED = "Builder Profile Shared";
export const BUILDER_CALENDAR_SHARED = "Builder Calendar Shared";
export const LOCKED_PROFILE_VIEWED = "Locked Profile Viewed";
export const ROLE_CATEGORY_SELECTED = "Role Category Selected";
export const REQUEST_TEAM_PROGRESS: string = "Request Team Progressed";
export const ROLE_CARD_CLICKED: string = "Role Card Clicked";
export const ROLE_FILTER_CLICKED: string = "Role Filter Clicked";
export const ROLE_FILTER_SELECTED: string = "Role Filter Selected";
export const SCHEDULING_LINK_ADDED = "Scheduling Link Added";
export const INTERVIEW_CONFIRMED = "Interview Confirmed";
export const RESCHEDULE_INTERVIEW = "Reschedule Interview";
export const CANCEL_INTERVIEW = "Cancel Interview";
export const BOOK_FOLLOWUP_INTERVIEW = "Book Follow Up Interview";
export const ADD_INTERVIEW_FEEDBACK = "Add Interview Feedback";
export const VIEW_INTERVIEW_RECORDING = "View Interview Recording";
export const PLAY_INTERVIEW_RECORDING = "Play Interview Recording";
export const SIGNUP_PROGRESS: string = "Signup Progressed";
export const TEAM_PROPOSAL_VIEWED = "Team Proposal Viewed";
export const CHATBOT_MESSAGE_SENT = "Chatbot Message Sent";
export const CHATBOT_COMPLETED = "Chatbot Completed";
export const TOOLTIP_HOVERED: string = "Tooltip Hovered";
export const VIDEO_PLAYED: string = "Video Played";
export const BANNER_VIEWED: string = "Banner Viewed";
export const BANNER_CLICKED: string = "Banner Clicked";
export const TOAST_VIEWED: string = "Toast Viewed";
export const TOAST_CLICKED: string = "Toast Clicked";
export const SPEC_CREATED: string = "Spec Created";
export const NUX_STEP_COMPLETED: string = "NUX Step Completed";
export const ADD_CUSTOM_ROLE_QUESTION_CLICKED: string =
  "Add custom role question button clicked";
export const VIEWED_NATIVE_PROPOSAL: string = "Viewed native proposal";
export const COLLABORATOR_ROLE_CHANGED: string = "Collaborator Role Changed";
export const CLIENT_INTERVIEW_SCHEDULED: string = "Client Interview Scheduled";
export const CHAT_OPENED: string = "Chat Opened";
export const CHAT_CLOSED: string = "Chat Closed";
export const CHAT_MESSAGE_SENT: string = "Chat Message Sent";
export const CHAT_MESSAGE_RECEIVED: string = "Chat Message Received";
export const DISCOVER_BUILDERS_FROM_ROLE_CLICKED =
  "Discover Builders Link Clicked";
export const UNAUTHORIZED_BOOK_INTERVIEW_CLICKED: string =
  "Book Interview Clicked Unauthorized";
export const CURATOR_FILTER_REMOVED: string = "Curator Filter Removed";
export const CURATOR_ROLE_REMOVED: string = "Curator Role Removed";
export const HUBSPOT_MEETING_SCHEDULED: string = "Hubspot Meeting Scheduled";
export const SIGN_UP_PAGE_VIEWED: string = "Sign-Up Page Viewed";
export const SIGN_UP_FORM_STARTED: string = "Sign-Up Form Started";
export const SIGN_UP_CONTINUE_WITH_GOOGLE_CLICKED: string =
  "Continue With Google Clicked";
export const SIGN_IN_WITH_GOOGLE_CLICKED = "Sign In With Google Clicked";
export const SIGN_UP_CONFIRM_EMAIL_PAGE_LOADED: string =
  "Confirm Your Email Page Loaded";
export const SIGN_UP_RESEND_CONFIRMATION_CLICLED: string =
  "Resend Confirmation Email Clicked";
export const CURATOR_EXAMPLE_CLICKED: string = "Curator Example Clicked";
export const CURATOR_CHAT_ADD_MORE_ROLES_BUTTON_CLICKED: string =
  "Curator Chat Add More Roles Button Clicked";
export const CURATOR_CHAT_EXPAND_MY_SEARCH_BUTTON_CLICKED: string =
  "Curator Chat Expand My Search Button Clicked";
export const CURATOR_CHAT_SUGGESTED_ROLE_CLICKED: string =
  "Curator Chat Suggested Role Clicked";
export const CURATOR_CHAT_OTHER_ROLE_CLICKED: string =
  "Curator Chat Other Role Clicked";
export const CURATOR_REFINE_SEARCH_CLICKED: string =
  "Curator Refine Search Clicked";
export const CURATOR_HANDOFF_REDIRECT: string = "Curator Handoff Redirect";
export const CURATOR_SPEC_SUBMITTED: string = "Curator Spec Submitted";
export const CURATOR_SPEC_BUILDER_REMOVED: string =
  "Curator Spec Builder Removed";
export const CURATOR_SEARCH_RESULTS_RECEIVED: string =
  "Curator Search Results Received";
export const CURATOR_SEARCH_LIMIT_REACHED: string =
  "Curator Search Limit Reached";
export const CURATOR_PAGE_VIEWED: string = "Curator Page Viewed";
export const PUBLIC_INTERVIEW_CTA_CLICKED: string =
  "Public Interview CTA Clicked";
export const PUBLIC_PROFILE_CARD_CLICKED: string =
  "Public Profile Card Clicked";
export const CURATOR_EXTERNAL_INTRO_MESSAGE: string =
  "Curator External Intro Message";
export const TFAI_ENTRY_POINT_SUGGESTION_CARD_CLICKED: string =
  "TFAIEntryPoint - Suggestion Card Clicked";
export const TFAI_ENTRY_POINT_FORM_SUBMITTED: string =
  "TFAIEntryPoint - Form Submitted";
export const STAR_RATING_CLICKED: string = "Star Rating Clicked";
export const INTERVIEW_SCHEDULE_ACTION_CLICKED: string =
  "Interview Schedule Action Clicked";
export const INTERVIEW_CANCELED = "Interview Canceled";
